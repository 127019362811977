/*
	Block Notes:
		Designed By: 		Travis Wicks
		Built By: 			Travis Wicks
		Built for:			Prana Planet
		Website:
		Version: 			1.0.0
		Last Editor:
		Last Edit Date:

*/




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Top 3 Area - Top Three Styles * * */

#topThreeTitles {
	padding-top: 60px;
}

.top-box-wrap {
	padding: 10px 12% 40px;
}

.top-box-wrap .box {
	padding: 40px 20px;
	background-color: $background-light;
	border-radius: 3px;
	height: 600px
}

.top-box-wrap a {
	text-decoration: none;
}

.top-box-wrap .box .this-text {
	padding: 10px 10% 40px;
	display: block;
}

.top-box-wrap .box .this-image {
	width: 180px;
	height: 180px;
	margin: 0 auto;
	border-radius: 50%;
	border: 3px solid #fff;
}




/* sm */
@media screen and (min-width: 768px) {
/* Top 3 */
	.top-box-wrap .box .this-image {
		width: 220px;
		height: 220px;
		margin: 0 auto;
		border-radius: 50%;
		border: 3px solid #fff;
	}
}

/* md */
@media screen and (min-width: 992px) {
/* Top 3 */
	.top-box-wrap {
		padding: 40px 5% 80px;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
/* Top 3 */
	.top-box-wrap {
		padding: 40px 3% 80px;
	}
}