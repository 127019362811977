/*
	Block Notes:
		Designed By:    Travis Wicks
		Built By:       Travis Wicks
		Built for:
		Website:
		Version:        1.0.0
    	Last Editor:
		Last Edit Date:

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Sub-pages - Sub Page Wrapper Styles  * * */

.sub-page-wrapper {
	padding-bottom: 30px;
	margin-top: 88px;
}

.sub-page-wrapper article {
	padding-bottom: 30px;
}

.sub-page-container {
	min-height: 550px
}

/* Sub page headers */

#subPageHead {
	position: relative;
	height: 500px;
	background-size: cover;
	background-position: center;
	background-color: #065084;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	border-bottom: 5px solid $green-dark;
}

	#subPageHead:after {
		content: '';
		display: block;
		position: relative;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba( 0, 0, 0, .5 );
	}

#subPageTagLine {
	margin-top: 0;
	font-size: 24px;
	color: #fff;
	position: relative;
	z-index: 1;
	text-align: right;
	font-family: $headings-font-family, cursive;
}


.sub-page-container {
	background-color: #fff;
	position: relative;
}




/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}