/*
	Block Notes:
		Designed By: 	Travis Wicks
		Built By: 		Travis Wicks
		Built for: 		Oregon Coast Comics
		Website: 		https://oregoncoastcomics.com/
		Version: 		1.0.0

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Featured Testimonials * * */

#featuredTestimonialsArea {
	background-color: $background-dark;
	color: #fff;
	min-height: 600px;
}

	#featuredTestimonialsArea .featured-testimonal {
		padding: 10px 2%;
		text-align: center;

		.read-more {
			color: #fff;
			font-weight: bold;
			font-size: 15px;
		}
		.read-more:hover {
			color: #ddd;
		}
	}

	#featuredTestimonialsArea h3,
	#featuredTestimonialsArea .h3 {
		color: #fff;
	}

	div.featured-testimonal:nth-child(2) {
		display: none;
	}

	#featuredTestimonialsCTA .btn {
		background-color: $background-medium;
		color: #fff;
	}
	#featuredTestimonialsCTA .btn:hover {
		background-color: darken($background-medium, 5%);
	}

#featuredTestimonialsImg {
	background-size: cover;
	background-position: center;
	text-align: center;
}

	#featuredTestimonialsImg:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba( 0, 0, 0, .7 );
		z-index: 0;
		
	}

	#featuredTestimonialLogo {
		height: auto;
		width: 80%;
		max-width: 420px;
		padding: 0;
		margin: 0 auto;
	}

#featuredTestimonialsArea .review-stars {
	color: #fff;
}

#featuredTestimonialsArea .review-name {
	color: #fff;
}





/* sm */
@media screen and (min-width: 768px) {
/* Featured Testimonials */
	#featuredTestimonialsCTA {
		padding-top: 30px;
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
/* Featured Testimonials */
	#featuredTestimonialsArea .featured-testimonal {
		padding: 10px 12%;
	}
}