/*
	Block Notes:
		Designed By: 		Travis Wicks
		Built By: 			Travis Wicks
		Built for:
		Website:
		Version: 			1.0.0
		Last Editor:
		Last Edit Date:

*/



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * header - Main Header Styles * * */

.admin-bar .navbar-fixed-top {
	top: 32px !important;
}

/* Header Styles */

#mainHeaderWrapper {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 10;
	width: 100%;
	-webkit-transition: all 800ms ease;
			transition: all 800ms ease;
}
	#mainHeaderWrapper.active {
		box-shadow: 0px 5px 5px rgba(0,0,0,.4);
	}
	.logged-in #mainHeaderWrapper {
		top: 32px;
	}

	#mainLogo {
		margin: 6px auto;
		height: 62px;
		display: block;
		-webkit-transition: all 500ms ease;
			transition: all 500ms ease;
	}
		.active #mainLogo {
			height: 45px;
		}


#mainNavigationWrapper {
	background-color: $background-dark;
	width: 100%;
}

.nav-wrapper {
	-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
}

.navbar {
	margin-bottom: 0;
	font-family: 'Oswald', sans-serif;
	min-height: auto;
	border: none;
}

.navbar-nav > li > a {
	padding-top: 2px;
	padding-bottom: 2px;
	color: #fff;
}

.nav > li > a:hover,
.nav > li > a:focus,
.nav > li.current_page_item > a {
	background-color: $background-medium;
}
	.nav .open > a,
	.nav .open > a:hover,
	.nav .open > a:focus {
		background-color: $background-medium;
	}

/* Head CTA */
.head-phone-cta  {
	font-family: 'Oswald';
	font-size: 24px;
	color: $brand-primary;
	margin: 4px auto 4px;
	text-align: center;
	font-weight: bold;
}

.head-phone-cta small {
	font-family: $font-family-serif;
	font-weight: normal;
	font-size: 16px;
}


/* Mobile Menu */
.navbar-toggle {
    background-color: $background-medium;
}
	.navbar-toggle:hover {
		background-color: darken($background-medium, 5%);
	}
	.navbar-toggle .icon-bar {
		background-color: #fff;
	}

.collapse.in .collapse {
	display: block;
}





/* sm */
@media screen and (min-width: 768px) {
/* Header Area */
	#mainHeaderWrapper {
		position: fixed;
	}
}

/* md */
@media screen and (min-width: 992px) {
/* Header Area */
	.head-phone-cta  {
		text-align: right;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
	
}