/*
	Block Notes:
		Designed By:    Travis Wicks
		Built By:       Travis Wicks
		Built for:
		Website:
		Version:        1.0.0
    	Last Editor:
		Last Edit Date:

*/


/* * * Start of Tools * * */

body {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	padding-top: 160px;
	line-height: 1.25;
}

.v-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.this-image {
	background-position: center;
	background-size: cover;
	min-height: 160px;
	border-radius: 3px;
}

.no-gutter {
	padding-left: 0;
	padding-right: 0;
}

.alignright {
	float: right;
	margin: 0 0 10px 15px;
}

.alignleft {
	float: left;
	margin: 0 15px 10px 0;
}

hr { 
	border: 0; 
	height: 1px; 
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); 
	width: 80%;
	margin: 40px auto;
}

/* Fluid Square Boxes */
.square-box{
    position: relative;
    overflow: hidden;
}
.square-box:before{
    content: "";
    display: block;
    padding-top: 100%;
}
.square-content{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Screen Height */
	.screen-height {
		height: 100vh;
		min-height: 400px;
	}

/* Title - Font */ 
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $brand-primary;
	font-family: $font-family-serif;
}

.btn {
	font-family: $font-family-serif;
	margin-top: 14px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 800;
	background-image: none;
	border: none;
	text-shadow: none;
}






/* sm */
@media screen and (min-width: 768px) {
/* Subpage Content Margins */
	body {
		padding-top: 100px;
	}

	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 10px 3% 30px;
	}
	
}

/* md */
@media screen and (min-width: 992px) {
/* Sub Page Header Styels */
	#subPageTagLine {
		margin-top: 100px;
		font-size: 52px;
	}

/* Subpage Content Margins */
	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 10px 4% 30px;
	}

}

/* lg */
@media screen and (min-width: 1200px) {
/* Subpage Content Margins */
	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 15px 7% 30px;
	}

}