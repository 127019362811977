/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Main Content Area Styles * * */

$slide-height: 260px;


	#mainContentWrapper {
		background-color: $background-dark;
		background-image: url('/wp-content/themes/po-safestart-iaq-theme/img/main-content-bg.png');
		background-position: center top;
		background-repeat: repeat-x;
		padding-top: 20px;
		padding-bottom: 20px;

	// Left side: Slider
		.slide {
			position: relative;
			background-size: cover;
			background-position: center;
			min-height: $slide-height;
		}

		/* UnSlider Nav Stuff */

		.unslider-nav {
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 100%;
		}

		.unslider-arrow.next,
		.unslider-arrow.prev {
			top: 50%;
			font-size: 32px;
			color: #fff;
		}

		.unslider-nav ol li {
			width: 10px;
			height: 10px;
			border: 2px solid #fff;
		}
			li.unslider-active {
				background-color: #fff !important;
			}

	// Right side: Content
		> .container {
			background-color: #fff;
			padding: 20px 15px;
			border-radius: 20px;

			.content {
				height: $slide-height
			}

			h3 {
				margin-top: 0px;
			}
		}

	}


/* sm */
@media screen and (min-width: 768px) {

	#mainContentWrapper {
		padding-top: 30px;
		padding-bottom: 30px;

		$slide-height: 320px;
		.slide {
			min-height: $slide-height;
		}

		> .container .content {
			height: $slide-height;
		}
	}
}

/* md */
@media screen and (min-width: 992px) {

}

/* lg */
@media screen and (min-width: 1200px) {

}
