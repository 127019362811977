/*
	Block Notes:
		Designed By:    Travis Wicks
		Built By:       Travis Wicks
		Built for:
		Website:
		Version:        1.0.0
    	Last Editor:
		Last Edit Date:

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Blog Page - Blog Styels * * */

.sub-page-wrapper header.blog-list {
	background-image: none;
	background-repeat: none;
	background-position: none;
	background-size: none;
	position: relative;
	height: auto;
	margin-bottom: 0;
}

.attachment-featured-image {
	width: 100%;
	height: auto;
}

.meta-col {
	text-align: right;
}

.blog .entry-date {
	display: block;
}

.byline {
	display: none;
}
time.updated {
	display: none;
}

.screen-reader-text {
	display: none;
}

.blog-entry-footer {
	border-top: 2px solid #E6E7E4;
	padding-top: 40px;
	margin-top: 20px;
}

/* Single Blog Page */

#blogBackBtn {
	padding-bottom: 14px;
}

#blogImg {
	padding-bottom: 10px;
}

.categories-tags-links {
	display: none;
}


/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}