

.staff-list {
    background-color: transparent;

    .staff-member-row {
        .staff-image {
            .image {
                box-shadow: 0px 0px 4px #ddd;
                border-radius: 4px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;

                &:before {
                    display: block;
                    content: '';
                    padding-top: 100%;
                }
            }
        }

        .btn {
            margin-bottom: 15px;
        }

        .long-bio-wrap {
            :last-child {
                margin-bottom: 0;
            }
        }

        .break {
            margin-top: 15px;
            margin-bottom: 15px;
            border-bottom: 2px solid #f1f1f1;
        }

        &:last-child {
            .break {
                display: none;
            }
        }
    }
}


/* sm */
@media screen and (min-width: 768px) {

    .staff-list {
        .staff-member-row {
            .break {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}
