/*
	Block Notes:
		Designed By: 		Travis Wicks
		Built By: 			Travis Wicks
		Built for:
		Website:
		Version: 			1.0.0
		Last Editor:
		Last Edit Date:

*/




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Flex Sections * * */

#flexArea .flex-content {
	height: auto;
}

#flexArea .this-text {
	height: 340px;
	position: relative;
	z-index: 1;
}


#flexArea .this-image {
	height: 240px;
	border-radius: 0;
	position: relative;
	z-index: 1;
}




/* sm */
@media screen and (min-width: 768px) {
/* Flex Sections */
	#flexArea .this-text,
	#flexArea .flex-content,
	#flexArea .this-image {
		height: 400px;
	}
}

/* md */
@media screen and (min-width: 992px) {
/* Flex Sections */
	#flexArea {
		margin-top: 60px;
	}
		#flexArea .this-flex {
			margin-bottom: 60px;
		}
	#flexArea .this-text,
	#flexArea .flex-content,
	#flexArea .this-image {
		height: 420px;
	}
	#flexArea .this-text {
		position: absolute;
		z-index: 3;
		top: 0;
		left: inherit;
		right: 0;
		padding-left: 0;
		padding-right: 0;
	}
		#flexArea .v-align {
			padding: 10px 0;
		}
		#flexArea .flex-card {
			background-color: #fff;
			padding-top: 35px;
			padding-bottom: 55px;
			padding-left: 14%;
			padding-right: 14%;
			box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, .1);
		}
	#flexArea .this-image {
		position: absolute;
		top: 0;
		left: 0;
		right: inherit;
	}
	#flexArea .this-flex:nth-child(odd) .this-text {
		left: 0;
		right: inherit;
	}
	#flexArea .this-flex:nth-child(odd) .this-image {
		left: inherit;
		right: 0;
	}
	#flexArea .this-flex:nth-child(odd) .flex-card {
		border-right: 10px solid $green-dark;
	}
	#flexArea .this-flex:nth-child(even) .flex-card {
		border-left: 10px solid $green-medium;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
/* Flex Sections */
	#flexArea .this-text,
	#flexArea .flex-content,
	#flexArea .this-image {
		height: 500px;
	}
}