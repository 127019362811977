/*
	Block Notes:
		Designed By: 	Travis Wicks
		Built By: 		Travis Wicks
		Built for: 		Prana Planet
		Website: 		Not online Yet
		Version: 		1.0.0
		Last Editor:	
		Last Edit:		

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Pages - Foot Contact Block * * */

#contactBlockWrapper {
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	padding-top: 40px;
	padding-bottom: 140px;

	h3 {
		color: $gray-dark;
		font-weight: 700;
		font-size: 20px;
		line-height: 1;
		margin-top: 15px;
		margin-bottom: 0px;
	}

	.phone {
		font-size: 34px;
		line-height: 1;
		color: $brand-primary;
		font-family: 'Oswald', sans-serif;
	}
		.phone .fa {
			color: $brand-primary;
			font-size: 30px;
			line-height: 1;
			margin-right: 10px; 
		}

	.email a {
		font-size: 30px;
		line-height: 1;
		color: $brand-primary;
		font-family: 'Oswald', sans-serif;
	}

	.this-text {
		padding-top: 3px;
		padding-bottom: 3px;
		margin: 3px 20%;
		color: $brand-primary;
	}

	.this-social .fa {
		font-size: 42px;
		line-height: 1;
		margin: 5px;
	}
}

	#contactBlockWrapper:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba( 0, 0, 0, .4 );
		z-index: 0;
	}

#contactArea {
	text-align: center;
	background-color: #fff;
	overflow: hidden;
	border-radius: 20px;
	position: relative;
	padding-top: 20px;
	max-width: 600px;
}

a #mapButton {
	padding: 20px 10% 40px;
	margin: 20px -15px -15px;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	text-decoration: none;
	background-color: $brand-primary;
}
	a #mapButton:hover {
		background-color: $btn-primary-border;
		text-decoration: none;
	}

	a #mapButton h2,
	a #mapButton p {
		color: #fff;
	}

a #mapButton .this-text {
	position: relative;
	z-index: 2;
}

a#bigButtonLink:hover {
	text-decoration: none;
}





/* sm */
@media screen and (min-width: 768px) {
/* Foot Contact Block */
	#contactBlockWrapper {
		padding-top: 60px;

		.phone {
			font-size: 48px;
		}

		.email a {
			font-size: 34px;
		}
	}
	#contactArea {
		padding-top: 14px;
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
/* Foot Contact */
	#contactBlockWrapper {
		padding-top: 100px;
		padding-bottom: 140px;
	}
}