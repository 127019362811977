/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Pages - Foot Copyright and Logo * * */

#footLogo {
	width: 100%;
	max-width: 300px;
	display: block;
	margin: -44px auto 0px;
	position: relative;
	z-index: 2;
}

#footCopyright {
	background-color: $green-very-dark;
	padding-bottom: 60px;
	color: $green-medium;

	a {
		color: $green-medium;
	}
		a:hover {
			color: darken($green-medium, 5%);
		}
}
