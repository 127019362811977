/*
	Block Notes:
		Built for:		Safestart IAQ
		Website:		http://www.safestartiaq.com
		Version:        1.0.0
*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

.gmap-custom-wrap {
	height: 360px;
}




/* sm */
@media screen and (min-width: 768px) {

	.gmap-custom-wrap {
		height: 500px;
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}