/*
	Block Notes:
		Designed By:    Travis Wicks
		Built By:       Travis Wicks
		Built for:
		Website:
		Version:        1.0.0
    	Last Editor:
		Last Edit Date:

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Testimonials Page * * */
.review-stars {
	font-size: 22px;
	color: $brand-primary;
}

.review-name {
}

/* BlockQuote */
blockquote {
	font-size: 16px;
}



/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}